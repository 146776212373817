.App {
  font-family: sans-serif;
  text-align: center;
  padding: 30px;
}

.result {
  margin-top: 10px;
}

